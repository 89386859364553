<template>
  <div class="home">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ name: 'Home' }">{{
        app_name
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ $t("menu.console") }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="line"></div>

    <div class="page-block">
      <div class="page-block-header" style="flex-direction: row">
        <div class="content-title">
          <p>
            {{ $t("dashboard.home.welcome") }},
            <span v-if="user_statistics.name === admin_name">{{
              $t("dashboard.home.admin_name")
            }}</span>
            {{ user_statistics.name }}
          </p>
        </div>
        <div style="display: flex; align-items: center">
          <el-button type="text" @click="logoutUser">
            <span style="display: flex; align-items: center"
              >{{ $t("buttons.logout") }}
              <span class="material-icons"> exit_to_app </span></span
            >
          </el-button>
        </div>
      </div>

      <div class="page-block-content">
        <div class="results-part">
          {{ $t("dashboard.home.current_time") }}: {{ local_time }}
        </div>
        <div></div>
      </div>
    </div>

    <el-row :gutter="12" class="console-cards">
      <el-col :span="12">
        <el-card shadow="never">
          <h3 style="margin-top: 0">
            <span v-if="user_statistics.name === admin_name"
              >{{ $t("dashboard.home.user") }} </span
            >{{ $t("dashboard.home.disk_space_usage") }}
          </h3>
          <div>
            <p>
              <span>{{
                (user_statistics.datahome_size * 1e-9).toPrecision(3)
              }}</span>
              GB
            </p>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never">
          <h3 style="margin-top: 0">
            <span v-if="user_statistics.name === admin_name"
              >{{ $t("dashboard.home.user") }} </span
            >{{ $t("dashboard.home.task_statistics") }}
          </h3>
          <div>
            <el-row :gutter="12" class="numerical-info-block-container">
              <el-col :span="12">
                <div class="numerical-info-block">
                  <p>
                    <span>{{ user_statistics.task_all }}</span>
                    {{ $t("dashboard.home.task") }}
                  </p>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="numerical-info-block">
                  <p>
                    <span>{{ user_statistics.task_finished }}</span>
                    {{ $t("dashboard.home.task_finished") }}
                  </p>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="12" class="numerical-info-block-container">
              <el-col :span="12">
                <div class="numerical-info-block">
                  <p>
                    <span>{{ user_statistics.task_running }}</span>
                    {{ $t("dashboard.home.task_running") }}
                  </p>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="numerical-info-block">
                  <p>
                    <span>{{ user_statistics.task_failed }}</span>
                    {{ $t("dashboard.home.task_failed") }}
                  </p>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="margin-top: 12px">
      <el-col :span="24">
        <el-card shadow="never">
          <h3 style="margin-top: 0">
            {{ $t("dashboard.home.nodes_status") }} ({{
              $t("dashboard.home.node_active_count")
            }}: {{ node_ids.length }})
          </h3>

          <el-row style="margin-top: 2px">
            <el-col :span="24">
              <div class="node-viz-list">
                <div
                  class="node-viz"
                  v-for="(node_id, key) in node_ids"
                  :key="key"
                >
                  <div class="node-container">
                    <div class="prg-container">
                      <code class="prg-text"
                        >CPU({{
                          nodes_status[node_id].cpu.cores
                        }}Cores):<br />{{
                          nodes_status[node_id].cpu.percentage
                        }}%
                      </code>
                      <div
                        class="prg-fill"
                        :style="{
                          height:
                            nodes_status[node_id].cpu.percentage *
                              shared_layout.node_scale +
                            'px',
                        }"
                      ></div>
                    </div>
                    <div class="prg-container">
                      <code class="prg-text"
                        >RAM:<br />{{
                          (nodes_status[node_id].mem.used / 1024 ** 3).toFixed(
                            2
                          )
                        }}/{{
                          Math.ceil(
                            nodes_status[node_id].mem.total / 1024 ** 3
                          )
                        }}G</code
                      >
                      <div
                        class="prg-fill"
                        :style="{
                          height:
                            (nodes_status[node_id].mem.used /
                              nodes_status[node_id].mem.total) *
                              100 *
                              shared_layout.node_scale +
                            'px',
                        }"
                      ></div>
                    </div>
                  </div>
                  <div class="node-info">
                    <span class="node-name" :title="nodes_status[node_id].name"
                      >{{ $t("dashboard.home.node") }}
                      {{ nodes_status[node_id].name }}</span
                    >:
                    <!-- {{ nodes_status[node_id].workers_status | worker_status }} -->
                    <span v-if="lang_status == 'en'">
                      {{ nodes_status[node_id].workers_status }}</span
                    >
                    <span v-else>{{
                      nodes_status[node_id].workers_status | worker_status_zhcn
                    }}</span>
                  </div>
                  <div class="node-updated-time">
                    {{ $t("dashboard.home.last_updated_at") }}:<br />
                    {{
                      (nodes_status[node_id].time * 1000)
                        | toLocalTime
                        | formatLocalTime
                    }}
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <div></div>
        </el-card>
      </el-col>
    </el-row>

    <el-row style="margin-top: 12px">
      <el-col :span="24">
        <el-card shadow="never">
          <h3 style="margin-top: 0">
            <span v-if="user_statistics.name === admin_name"
              >{{ $t("dashboard.home.user") }} </span
            >{{ $t("dashboard.home.task_history") }}
          </h3>
          <div>
            <calendar-heatmap
              tooltip-unit:="$t('dashboard.home.task_commit')"
              :endDate="endDate"
              :values="task_history"
            />
          </div>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import apiURL from "@/data/api";
import moment from "moment";
import { CalendarHeatmap } from "vue-calendar-heatmap";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  name: "Home",
  components: {
    CalendarHeatmap,
  },
  data: () => ({
    endDate: new Date(),
    task_history: [],
    ws_notification_client: null,
    node_p_scale: 0.8,
  }),
  beforeMount() {
    this.switchDefaultPage("/dashboard");
  },
  async mounted() {
    this.getCurrentUserInfo();
    this.getTaskHistory();
    console.log("1");

    console.log("2");
  },
  computed: {
    ...mapGetters(["headers4reqs", "access_token", "node_ids"]),
    ...mapState({
      user_statistics: (state) => state.user.statistics,
      admin_name: (state) => state.admin_name,
      app_name: (state) => state.app_name,
      nodes_status: (state) => state.nodes_status,
      lang_status: (state) => state.lang_status,
    }),
    local_time() {
      return moment().format("YYYY-MM-DD HH:mm:ss");
    },
    node_p_h() {
      return this.node_p_scale * 100 + "px";
    },
  },
  methods: {
    ...mapActions([
      "getCurrentUserInfo",
      "removeUserToken",
      "switchDefaultPage",
    ]),

    async logoutUser() {
      let removed = await this.removeUserToken();
      if (removed) {
        this.$message(this.$t("dashboard.home.signout_message"));
        this.$router.push("/login");
      }
    },
    getTaskHistory() {
      this.$get_api(apiURL.task.history, null, this.headers4reqs, {
        mins_offset: new Date().getTimezoneOffset(),
      }).then((res) => {
        console.log(res);
        var res_d = [];
        for (let i = 0; i < res.length; i++) {
          res_d = res.map((x) => {
            return {
              date: moment(x.date, "YYYY-MM-DD"),
              count: x.count,
            };
          });
        }
        this.task_history = res_d;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/main";

.node-viz-list {
  display: flex;
  flex-wrap: wrap;
  // background: red;
  .node-viz {
    $prg-height: 100px * $node_scale;
    $prg-width: 200px * $node_scale;
    $rd: $card-border-radius;
    $pd: 10px;

    margin-right: $prg-width/8;
    width: $prg-width * 2 + $pd * 3;
    display: flex;
    flex-direction: column;

    background: #f2f2f2;
    border-radius: $rd;

    .node-info {
      cursor: pointer;
      padding-left: $pd;
      padding-right: $pd;
      font-size: 0.9rem;
      .node-name {
        max-width: $prg-width * 0.7;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .node-updated-time {
      color: rgb(122, 122, 122);
      padding: $pd;
      padding-top: 0;
      font-size: 0.7rem;
    }
    .node-container {
      padding: $pd;
      border-radius: $rd;

      display: flex;
      justify-content: space-between;

      .prg-container {
        height: $prg-height;
        width: $prg-width;
        background: black;
        border-radius: $rd;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        position: relative;
        // margin-right: $pd;

        .prg-text {
          color: white;
          z-index: 9;
          position: absolute;
          top: $rd;
          width: $prg-width;
          text-align: center;
        }
        .prg-fill {
          width: $prg-width;
          // background: #37b334;
          background: #409eff;
        }
      }
    }
  }
}

.home {
  .console-cards {
    $card-height: 200px;
    .el-card {
      height: 100%;
      min-height: $card-height;

      p {
        cursor: pointer;
        font-size: 0.8rem;
        margin: 0;

        span {
          font-weight: bold;
          font-size: 1.5rem;
        }
      }
    }
  }

  $site-area-space: 10px;
  .numerical-info-block-container {
    margin-bottom: -$site-area-space;

    .el-col {
      margin-bottom: $site-area-space;
    }

    .numerical-info-block {
      max-height: 70px;
      border-radius: $card-border-radius;
      background-color: RGBA(243, 243, 243, 1);
      display: flex;
      justify-content: space-between;
      padding: $site-area-space;

      p {
        cursor: pointer;
        font-size: 0.8rem;
        margin: 0;

        span {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
